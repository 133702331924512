import { RootWrapper } from 'store/components/RootWrapper'

import 'normalize.css/normalize.css'
import 'styles/global.scss'

export const wrapRootElement = RootWrapper

export {
  wrapPageElement,
  onClientEntry,
} from '@arch-log/gatsby-plugin-page-context'
