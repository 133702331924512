import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import { useAuth } from '@arch-log/webapp.modules/auth.v2/components'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const InProject = withDefaultRenderComponent(
  ({
    enableLogoLink = true,
    enableSignIn = true,
    enableSignUp = true,
    showHowToLink = true,
    components: {
      Logo: logoComponents,
      AuthenticatedMenu: authenticatedMenuComponents,
      Layout: {
        Container = Layout.InProjectContainer,
        LogoSection = Layout.Logo,
        LogoProject = Layout.LogoProject,
      } = {},
    } = {},
  }) => (
    <Container>
      <LogoSection>
        <Containers.Logo
          components={logoComponents}
          canNavigate={enableLogoLink}
        />
        <LogoProject />
      </LogoSection>
      <AuthenticatedMenu
        components={authenticatedMenuComponents}
        enableSignIn={enableSignIn}
        enableSignUp={enableSignUp}
        showHowToLink={showHowToLink}
      />
    </Container>
  ),
)

const AuthenticatedMenu = ({
  enableSignUp,
  enableSignIn,
  showHowToLink,
  components: {
    Menu: menuComponents,
    Layout: {
      Wrapper = Layout.Wrapper,
      SignUpSection = Layout.SignUp,
      SignInSection = Layout.SignIn,
      MenuSection = Layout.Menu,
      HowToSection = Layout.HowTo,
    } = {},
  } = {},
}) => {
  const { loaded, isAuthenticated } = useAuth()

  if (!loaded) {
    return null
  }

  if (isAuthenticated) {
    return (
      <Wrapper>
        <MenuSection>
          <Containers.Menu components={menuComponents} />
        </MenuSection>
        <HowToSection isVisible={showHowToLink}>
          <Containers.ToHowTo />
        </HowToSection>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <SignUpSection isVisible={enableSignUp}>
        <Containers.SignUp />
      </SignUpSection>
      <SignInSection isVisible={enableSignIn}>
        <Containers.SignIn />
      </SignInSection>
      <MenuSection>
        <Containers.LogoutMenu components={menuComponents} />
      </MenuSection>
      <HowToSection isVisible={showHowToLink}>
        <Containers.ToHowTo />
      </HowToSection>
    </Wrapper>
  )
}
