export const ACTION_PREFIX = 'MODULE/ACCOUNT'

const keyValue = (key, value) => ({ key, value })

export const TypesOfBusiness = [
  keyValue(0, 'owner_corporation'),
  keyValue(1, 'owner_private'),
  keyValue(2, 'owner_government'),
  keyValue(3, 'owner_others'),
  keyValue(4, 'designer_general'),
  keyValue(5, 'designer_storel'),
  keyValue(6, 'designer_residential'),
  keyValue(7, 'designer_others'),
  keyValue(8, 'contractor_general'),
  keyValue(9, 'contractor_store'),
  keyValue(10, 'contractor_house'),
  keyValue(11, 'contractor_others'),
  keyValue(12, 'manufacturer'),
]
