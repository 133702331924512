import React from 'react'
import axios from 'axios'

import { Context } from './Context'

import * as config from '@arch-log/webapp.lib/config'
import * as Errors from '@arch-log/webapp.lib/errors'

import { initAuthorization, getInstance } from './OnMemoryStorage'

import * as Log from '@arch-log/webapp.modules/log/components'

/**
 */
export const FromSessionInitializer = ({
  components: { Loading = null } = {},
  throwGatewayError = true,
  throwNetworkError = true,
  throwClientError = true,
  throwError = false,
  children,
}) => {
  const [state, setState] = React.useState({ loaded: false })
  const [error, setError] = React.useState(null)
  const { deleteCookiesLoginInfo, pushUserIdDataLayer } =
    Log.gtmEventDispatcher()

  React.useLayoutEffect(() => {
    ;(async () => {
      const data = await (async () => {
        try {
          const { data = {} } = await axios.post(
            `${config.get('auth.tokenProvider.baseUrl')}/session`,
            {},
            { withCredentials: true, timeout: 3000 },
          )

          return data
        } catch (cause) {
          console.error(error)

          initAuthorization(null)
          deleteCookiesLoginInfo()

          setError(error)
          return {}
        }
      })()
      if (!data?.access_token) {
        deleteCookiesLoginInfo()
      }

      initAuthorization(data?.access_token)

      setState({
        uid: data?.uid,
        user_id: data?.user_id,
        authorization: getInstance().getAuthorization(),
        loaded: true,
      })
      pushUserIdDataLayer(data?.uid)
    })()
  }, [])

  if (error) {
    if (error.code && throwNetworkError) {
      throw Errors.NetworkError
    }

    if (throwGatewayError) {
      switch (error.response?.status) {
        case 502:
        case 503:
        case 504:
          throw Errors.GatewayError
        default:
          break
      }
    }

    if (throwClientError) {
      switch (error.response?.status) {
        case 400:
          break
        case 401:
          //Unauthorized
          break
        case 403:
          //Forbidden
          break
        case 404:
          throw Errors.ApiNotFound
        case 405:
          break
        case 429:
          throw Errors.TooManyRequest
        default:
          break
      }
    }

    if (throwError) {
      // to be added later
    }
  }

  if (!state?.loaded && Loading) {
    return <Loading />
  }

  return <Context.Provider value={state}>{children}</Context.Provider>
}
