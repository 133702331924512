import React from 'react'

import TagManager from 'react-gtm-module'
import Cookies from 'js-cookie'

import { TypesOfBusiness } from '@arch-log/webapp.modules/account/consts'
import * as config from '@arch-log/webapp.modules/config/config'

export const gtmEventDispatcher = () => {
  const pushEventToGTM = (eventName, additionalData = {}) => {
    const dataLayerObject = {
      event: eventName,
      ...additionalData,
    }

    TagManager.dataLayer({ dataLayer: dataLayerObject })
  }

  const pushProjectDataLayer = (projectId) => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        project_id: projectId,
      })
    }
  }

  const pushUserIdDataLayer = (userId) => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        user_id: userId,
      })
    }
  }

  /**
   * 製品サムネイルクリックイベントをGTMに送信
   * @param {Object} product
   * @param {Object} condition　// Searchから呼ばれた時の条件を入れる
   * @param string feature　// どの機能からのイベントかを示す
   */
  const pushExpandEvent = (product, condition, feature) => {
    const itemName = `${product.identifier?.codes?.current ?? ''}_${
      product.identifier?.name ?? ''
    }`
    const perPieceValue =
      product.costs
        ?.find((item) => item.label === 'per_piece')
        ?.value?.toString() || null

    pushEventToGTM('expand', {
      feature: feature,
      filter_category: null, // productのcategoryの全ての階層が取得できるようになったら修正
      filter_category2: null, // productのcategoryの全ての階層が取得できるようになったら修正
      filter_category3: condition?.category ?? null,
      filter_manufacturer: condition?.manufacturer ?? null,
      filter_bim: condition?.onlyBimSupported ? 1 : 0,
      filter_text: condition?.keyword?.join(' ') ?? null,
      ecommerce: {
        items: [
          {
            item_id: product?.id ?? null,
            item_name: itemName,
            item_brand: product.identifier?.manufacturer?.name?.ja ?? null,
            item_categories:
              product.categories?.map((category) => ({
                item_category: null,
                item_category2: null,
                item_category3: category,
              })) ?? [],
            price: perPieceValue,
          },
        ],
      },
    })
  }

  /**
   * 製品のリンククリックイベントをGTMに送信
   * @param {Object} link
   */
  const pushProductLinkClickEvent = (link) => {
    const { url, title, bim: isBimLink } = link
    pushEventToGTM(isBimLink ? 'BIM_link_click' : 'other_link_click', {
      link_text: title ?? '',
      link_url: url,
      link_target: '_blank',
    })
  }

  const setCookiesLoginInfo = (account) => {
    const industryEntry = TypesOfBusiness.find(
      (item) => item.key === Number(account.industry),
    )
    const currentDomain = config.get('site.hostname')
    const isStaging = currentDomain.endsWith('staging.arch-log.com')
    const isProduction = currentDomain.endsWith('arch-log.com') && !isStaging

    const domain = isStaging
      ? '.staging.arch-log.com'
      : isProduction
      ? '.arch-log.com'
      : null

    if (!domain) return

    const cookieOptions = {
      path: '/',
      secure: true,
      domain,
    }

    const cookiesData = {
      gtm_user_id: account.id,
      gtm_domain: account.email.split('@')[1],
      gtm_user_company: account.company,
      gtm_user_division: account.division,
      gtm_user_industry: industryEntry?.value || 'unknown',
    }

    Object.entries(cookiesData).forEach(([key, value]) => {
      Cookies.set(key, value, cookieOptions)
    })
  }

  const deleteCookiesLoginInfo = () => {
    const currentDomain = config.get('site.hostname')
    const isStaging = currentDomain.endsWith('staging.arch-log.com')
    const isProduction = currentDomain.endsWith('arch-log.com') && !isStaging

    const domain = isStaging
      ? '.staging.arch-log.com'
      : isProduction
      ? '.arch-log.com'
      : null

    if (!domain) return

    const cookieOptions = {
      path: '/',
      domain,
    }

    const cookieKeys = [
      'gtm_user_id',
      'gtm_domain',
      'gtm_user_company',
      'gtm_user_division',
      'gtm_user_industry',
    ]

    cookieKeys.forEach((key) => {
      Cookies.remove(key, cookieOptions)
    })
  }

  const pushLoginEvent = (account) => {
    const industryEntry = TypesOfBusiness.find(
      (item) => item.key === Number(account.industry),
    )
    pushEventToGTM('login', {
      user_id: account.id,
      domain: account.email.split('@')[1],
      user_company: account.company,
      user_division: account.division,
      user_industry: industryEntry ? industryEntry.value : 'unknown',
    })
  }

  const pushSignUpStartEvent = () => pushEventToGTM('sign_up_start')
  const pushSignUpCompleteEvent = () => pushEventToGTM('sign_up')
  const pushNewProjectStartEvent = () => pushEventToGTM('new_project_start')
  const pushNewProjectCompleteEvent = () => pushEventToGTM('new_project')

  return {
    deleteCookiesLoginInfo,
    setCookiesLoginInfo,
    pushProjectDataLayer,
    pushUserIdDataLayer,
    pushExpandEvent,
    pushProductLinkClickEvent,
    pushSignUpStartEvent,
    pushSignUpCompleteEvent,
    pushLoginEvent,
    pushNewProjectStartEvent,
    pushNewProjectCompleteEvent,
  }
}
